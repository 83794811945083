import { Snackbar } from "@chef/components";
import { isAccountBadPayer, isAccountPending } from "@chef/helpers";
import { useBillingQuery } from "@chef/state-management";

import { intl } from "./AccountStatusWarning.Intl";

export const AccountStatusWarning = () => {
  const { data: billingData } = useBillingQuery();

  const accountStatus = billingData?.billing?.status;

  if (!accountStatus) {
    return null;
  }

  const isPending = isAccountPending(accountStatus);
  const isBadPayer = isAccountBadPayer(accountStatus);

  if (isPending) {
    return <Snackbar warning>{intl.INVALID_PAYMENT_METHOD}</Snackbar>;
  }

  if (isBadPayer) {
    return (
      <Snackbar warning>
        {intl.CONTACT_CS_FOR_AN_OVERVIEW_UNPAID_ORDERS}
      </Snackbar>
    );
  }

  return null;
};
