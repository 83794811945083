import type { PortableTextBlock } from "@portabletext/types";
import { PortableText } from "@portabletext/react";
import Link from "next/link";
import React, { useCallback, useContext } from "react";
import clsx from "clsx";

import { PropsOf, Skeleton } from "@chef/components";
import { useTrack } from "@chef/feature-tracking";
import { DiscountsContext } from "@chef/state-management/components";
import {
  useBillingQuery,
  useMeQuery,
  useUserInfoQuery,
} from "@chef/state-management";
import { LINKS } from "@chef/constants";
import { CurrencyWrapper } from "@chef/smart-components";

import { ImageComponent } from "./Image";
import { CardsComponent } from "./Cards";
import { Prose } from "./Prose";
import { RecipesComponent } from "./Recipes";
import { AccordionComponent } from "./Accordion";
import { TrustPilotComponent } from "./TrustPilot";
import { VideoComponent } from "./Video";
import { RelatedComponent } from "./Related";
import { ColumnsComponent } from "./Columns";
import { HeroComponent } from "./Hero";
import { CTAComponent } from "./CTA";
import { BlockComponent } from "./Block";
import { SpacerComponent } from "./Spacer";
import { TrustBoxComponent } from "./TrustBox";
import { TagComponent } from "./Tag";
import { TableComponent } from "./Table";
import { TimetableComponent } from "./Timetable";
import { Step } from "./Step";
import { CenteredHeroComponent } from "./CenteredHero";
import { CarouselComponent } from "./Carousel";
import { DeliveryCheckerComponent } from "./DeliveryChecker";
import { GridComponent } from "./Grid";
import { CardComponent } from "./Card";
import { LinkWrapperComponent } from "./LinkWrapper";
import { LoyaltyTableComponent } from "./LoyaltyTable";

import { DisclosureComponent } from "./Disclosure";
import { InformationCardComponent } from "./InformationCard";
import { BannerComponent } from "./Banner";

export interface BlockContentProps {
  body: PortableTextBlock | PortableTextBlock[];
  prose?: Omit<PropsOf<typeof Prose>, "children"> & {
    disabled?: boolean;
  };
}

interface SerializedContentProps {
  body: PortableTextBlock | PortableTextBlock[];
}

const convertReactNodeToString = (node: React.ReactNode): string => {
  if (typeof node === "string") {
    return node;
  }

  if (Array.isArray(node)) {
    return node.map(convertReactNodeToString).join("");
  }

  if (React.isValidElement(node)) {
    return convertReactNodeToString(node.props.children);
  }

  return "";
};

const useMagicString = () => {
  const discountContext = useContext(DiscountsContext);

  const { data: isLoggedIn } = useMeQuery();
  const { data: userInfoQuery } = useUserInfoQuery(undefined, {
    skip: !isLoggedIn,
  });

  const { data: billingQuery } = useBillingQuery(undefined, {
    skip: !isLoggedIn,
  });

  const firstName = userInfoQuery?.userInfo.firstName;
  const lastName = userInfoQuery?.userInfo.lastName;

  const referralCode = billingQuery?.billing.couponCode;

  const fn = useCallback(
    (field: string) => {
      if (!field) {
        return null;
      }

      if (!discountContext) {
        return null;
      }

      switch (field) {
        case "%%DISCOUNT_MAX_SAVINGS%%":
          return (
            <CurrencyWrapper>
              {discountContext.magic.get("MAX_SAVINGS")}
            </CurrencyWrapper>
          );
        case "%%DISCOUNT_MAX_SAVINGS_PER_PORTION%%":
          return (
            <CurrencyWrapper>
              {discountContext.magic.get("MAX_SAVINGS_PER_PORTION")}
            </CurrencyWrapper>
          );
        case "%%DISCOUNT_MIN_PRICE_PER_PORTION%%":
          return (
            <CurrencyWrapper>
              {discountContext.magic.get("MIN_PRICE_PER_PORTION")}
            </CurrencyWrapper>
          );
        case "%%DISCOUNT_AMOUNT%%":
          if (discountContext.magic.get("TYPE") === "kr") {
            return (
              <CurrencyWrapper>
                {discountContext.magic.get("AMOUNT")}
              </CurrencyWrapper>
            );
          } else {
            return discountContext.magic.get("AMOUNT");
          }
        case "%%DISCOUNT_TYPE%%":
          return discountContext.magic.get("TYPE");
        case "%%DISCOUNT_USES_LEFT%%":
          return discountContext.magic.get("USES_LEFT");
        case "%%DISCOUNT_EXPIRATION_DATE%%":
          return discountContext.magic.get("EXPIRATION_DATE");
        case "%%FIRST_NAME%%":
          return firstName;
        case "%%LAST_NAME%%":
          return lastName;
        case "%%REFERRAL_LINK%%":
          return LINKS.REFERRAL_LINK(referralCode);
        case "%%TERMS_AND_CONDITIONS_LINK%%":
          return LINKS.AGREEMENT_LINK;
      }

      return null;
    },
    [discountContext, firstName, referralCode],
  );

  return fn;
};

export const SerializedContent = ({ body }: SerializedContentProps) => {
  const track = useTrack();
  const getMagicStringValue = useMagicString();

  return (
    <PortableText
      value={body}
      onMissingComponent={(message, options) => {
        console.error(message, options);
      }}
      components={{
        types: {
          img: ImageComponent,
          hero: HeroComponent,
          richBlock: BlockComponent,
          columns: ColumnsComponent,
          cards: CardsComponent,
          recipesFromTagCollection: RecipesComponent,
          specificRecipes: RecipesComponent,
          accordion: AccordionComponent,
          disclosure: DisclosureComponent,
          trustpilot: TrustPilotComponent,
          trustbox: TrustBoxComponent,
          video: VideoComponent,
          related: RelatedComponent,
          cta: CTAComponent,
          spacer: SpacerComponent,
          tag: TagComponent,
          table: TableComponent,
          timetable: TimetableComponent,
          step: Step,
          centeredHero: CenteredHeroComponent,
          carousel: CarouselComponent,
          deliveryChecker: DeliveryCheckerComponent,
          grid: GridComponent,
          card: CardComponent,
          linkWrapper: LinkWrapperComponent,
          loyaltyTable: LoyaltyTableComponent,
          informationCard: InformationCardComponent,
          banner: BannerComponent,
        },
        block: ({ value, children }) => {
          if (!value || !children) {
            return null;
          }

          switch (value.style) {
            case "small":
              return <div className="text-sm">{children}</div>;
            case "h1":
              return <h1>{children}</h1>;
            case "h2":
              return <h2>{children}</h2>;
            case "h3":
              return <h3>{children}</h3>;
            case "blockquote":
              return <blockquote>{children}</blockquote>;
            default:
              return <p>{children}</p>;
          }
        },
        list: {
          bullet: ({ children }) => (
            <ul className="list-disc list-inside">{children}</ul>
          ),
          number: ({ children }) => (
            <ol className="list-decimal list-inside">{children}</ol>
          ),
          check: ({ children }) => (
            <ul
              className="list-inside"
              style={{
                listStyleImage:
                  'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="0.75em" height="0.75em" viewBox="0 0 12 12" fill="none"><path d="M1 6.5L4.33333 10L11 3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/></svg>\')',
              }}
            >
              {children}
            </ul>
          ),
        },
        marks: {
          right: ({ children }) => {
            return <span className="block text-right">{children}</span>;
          },
          center: ({ children }) => {
            return <span className="block text-center">{children}</span>;
          },
          color: ({ value, children }) => {
            return <span style={{ color: value.hex }}>{children}</span>;
          },
          magicString: ({ children }) => {
            const value = convertReactNodeToString(children);

            const str = getMagicStringValue(value);

            if (!str) {
              return (
                <Skeleton width={24} height={4} className="inline-block mx-2" />
              );
            }

            return <span>{str}</span>;
          },

          customLink: ({ value, children }) => {
            if (!value) {
              return children;
            }

            const handleLinkClicked = () => {
              if (!value || !value.trackingName) {
                return;
              }

              track("linkClicked", {
                affiliation: "Frontend process",
                link_text: value.text,
                link_source: "sanity",
                link_destination: value.href,
                link_placement: value.trackingName,
                link_action: "click",
              });
            };

            const scrollIntoView = () => {
              setTimeout(() => {
                const target = document.getElementById(value.scrollId);
                target?.scrollIntoView({ behavior: "smooth" });
              }, 0);
            };

            const isInternal = value.href?.startsWith("/");

            if (isInternal) {
              return (
                <Link
                  href={value.href}
                  onClick={handleLinkClicked}
                  className="underline"
                >
                  {children}
                </Link>
              );
            }

            if (value.scrollId) {
              return (
                <Link
                  href={value.scrollId}
                  onClick={scrollIntoView}
                  className="underline"
                >
                  {children}
                </Link>
              );
            }

            return (
              <a
                href={value.href}
                onClick={handleLinkClicked}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {children}
              </a>
            );
          },
        },
      }}
    />
  );
};

export const BlockContent = ({ body, prose }: BlockContentProps) => {
  if (prose?.disabled === true) {
    return (
      <div className={clsx(prose?.height === "full" && "h-full", "not-prose")}>
        <SerializedContent body={body} />
      </div>
    );
  }

  return (
    <Prose
      maxWidth={prose?.maxWidth || "none"}
      padding={prose?.padding || "around"}
      invert={prose?.invert}
      height={prose?.height}
    >
      <SerializedContent body={body} />
    </Prose>
  );
};
