import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from "next";
import { useRouter } from "next/router";

import { AppShell } from "@chef/feature-app-shell";
import { useTrackExperiment } from "@chef/feature-flags";
import { BRAND_NAME } from "@chef/constants";

import { type ILanding, getLandingData } from "../queries";
import { useLandingData } from "../hooks";
import { ContentComponent } from "../components";
import { SanityQuery } from "../types";

export const LandingPage = (
  props: InferGetStaticPropsType<typeof LandingGetStaticProps>,
) => {
  const [landing] = useLandingData(props.landing);

  if (!landing) {
    return null;
  }

  return (
    <ContentComponent block={landing.content}>
      {({ children, title, description, imageUrl, index }) => (
        <AppShell
          title={title}
          description={description}
          image={imageUrl}
          index={index}
          canonical="/"
        >
          <AppShell.Meta />
          <AppShell.Header />
          <AppShell.DiscountBanner />
          <AppShell.Main noPadding>{children}</AppShell.Main>
          <AppShell.Footer />
        </AppShell>
      )}
    </ContentComponent>
  );
};

export const VariantsLandingPage = (
  props: InferGetStaticPropsType<typeof VariantsLandingGetStaticProps>,
) => {
  const [landing] = useLandingData(props.landing);
  const router = useRouter();

  useTrackExperiment(props.meta?.flag, {
    enabled: true,
    name: router.query.variant as string,
    payload: {
      type: "string",
      value: landing?.content.title,
    },
  });

  if (!landing) {
    return null;
  }

  return (
    <ContentComponent block={landing.content}>
      {({ children, title, description, imageUrl, index }) => (
        <AppShell
          title={title}
          description={description}
          image={imageUrl}
          index={index}
          canonical="/"
        >
          <AppShell.Meta />
          <AppShell.Header />
          <AppShell.DiscountBanner />
          <AppShell.Main noPadding>{children}</AppShell.Main>
          <AppShell.Footer />
        </AppShell>
      )}
    </ContentComponent>
  );
};

export const LandingGetStaticProps: GetStaticProps<
  {
    draftMode: boolean;
    landing: ILanding;
  },
  SanityQuery
> = async ({ draftMode = false }) => {
  const landing = await getLandingData(null, { preview: draftMode });

  if (!landing || !landing.content) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  return {
    props: {
      draftMode,
      landing,
    },
    revalidate: 600,
  };
};

export const VariantsLandingGetStaticProps: GetStaticProps<
  {
    draftMode: boolean;
    landing: ILanding;
    meta?: { flag: string };
  },
  SanityQuery
> = async ({ draftMode = false, params }) => {
  const landing = await getLandingData(null, { preview: draftMode });

  if (!landing || !landing.content) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  const variant = params?.variant;

  if (landing.content._type === "experiment" && variant) {
    const content = landing.content.variants.find((v) => v._id === variant);

    if (content) {
      const flag = `${BRAND_NAME.toLowerCase()}_${
        landing.content.slug.current
      }`;

      return {
        props: {
          draftMode,
          landing: { ...landing, content },
          meta: {
            flag,
          },
        },
        revalidate: 600,
      };
    }
  }

  return {
    props: {
      draftMode,
      landing,
    },
    revalidate: 600,
  };
};

export const VariantsLandingGetStaticPaths: GetStaticPaths = async () => {
  const landing = await getLandingData(null, { preview: false });

  // added due to migration set not having this, can be removed when merging LGF-4280
  if (!landing || !landing.content) {
    return {
      paths: [],
      fallback: "blocking",
    };
  }

  if (landing.content._type === "experiment") {
    return {
      paths: landing.content.variants.map((variant) => ({
        params: {
          variant: variant._id,
        },
      })),
      fallback: "blocking",
    };
  }

  return {
    paths: [{ params: { variant: landing.content._id } }],
    fallback: "blocking",
  };
};
