import { createApi } from "@reduxjs/toolkit/query/react";

import type { RecursivePartial } from "@chef/types/utils";
import type { BillingSession } from "@chef/types/payment";

import { query } from "../../graphql/baseApi";

type IGetBillingSessionResponse = RecursivePartial<BillingSession>;

type IUpdateBillingSessionArgs = RecursivePartial<BillingSession>;

type ICreateBillingSessionArgs = RecursivePartial<BillingSession>;

type IUpdateBillingSessionResponse = {
  redirectUri: string;
};

type ICreateBillingSessionResponse = {
  redirectUri: string;
};

export const billingSessionApi = createApi({
  reducerPath: "billingSession",
  baseQuery: query,
  tagTypes: ["billingSession"],
  endpoints: (builder) => ({
    getBillingSession: builder.query<IGetBillingSessionResponse, void>({
      query: () => ({
        url: `/billing/session`,
        method: "GET",
      }),
      providesTags: ["billingSession"],
    }),
    createBillingSession: builder.mutation<
      ICreateBillingSessionResponse,
      ICreateBillingSessionArgs
    >({
      query: (body) => ({
        url: "/billing/session",
        method: "POST",
        body,
      }),
      invalidatesTags: ["billingSession"],
    }),
    updateBillingSession: builder.mutation<
      IUpdateBillingSessionResponse,
      IUpdateBillingSessionArgs
    >({
      query: (body) => ({
        url: "/billing/session",
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["billingSession"],
    }),
  }),
});

export const {
  useGetBillingSessionQuery,
  useUpdateBillingSessionMutation,
  useCreateBillingSessionMutation,
} = billingSessionApi;
