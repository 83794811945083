import Link from "next/link";

import { useTrack } from "@chef/feature-tracking";

interface IBanner {
  _type: "banner";
  _key: string;
  text: string;
  buttonText: string;
  href: string;
}

interface BannerComponentProps {
  value: IBanner;
}

export const BannerComponent = ({ value }: BannerComponentProps) => {
  const track = useTrack();
  if (!value) {
    return null;
  }
  const handleClick = () => {
    track("buttonClicked", {
      affiliation: "Frontend process",
      button_colour: "custom_outlined",
      button_text: value.buttonText,
      button_source: "sanity",
      button_destination: value.href,
      button_placement: "banner",
      button_action: "click",
    });
  };
  return (
    <Link onClick={handleClick} href={value.href} className="no-underline">
      <div className="bg-primary w-screen stretch-x">
        <div className="page-max-width text-white flex gap-6  items-center justify-between py-3 md:py-6 px-4 ">
          <div aria-hidden="true" className="hidden md:w-32 md:block"></div>
          <div className="font-serif text-base md:text-lg md:text-center">
            {value.text}
          </div>
          <div className="md:w-32">
	          <button className="text-sm rounded-full border-solid border-2 border-highlight bg-transparent text-nowrap p-3">
	            {value.buttonText}
	          </button>
          </div>
        </div>
      </div>
    </Link>
  );
};
