import { Menu, Transition } from "@headlessui/react";

import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";

import { Hamburger } from "@chef/icons/small";

interface Item {
  label: string;
  href: string;
}

interface DropdownMenuProps {
  items: Item[];
  className?: string;
}

export const DropdownMenu = ({ items, className }: DropdownMenuProps) => {
  const router = useRouter();

  const isSelected = (item: Item) => router.asPath === item.href;

  const selectedTitle = items.find(isSelected)?.label;

  const itemsToShow = items.filter((item) => !isSelected(item));

  return (
    <Menu as="nav" className={clsx(className, "grid relative")}>
      <Menu.Button>
        <div className="flex items-center justify-between p-3 border-1.5 rounded">
          <div>
            <strong>{selectedTitle}</strong>
          </div>
          <Hamburger />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          as="div"
          className="absolute z-20 w-full bg-white rounded shadow top-15"
        >
          {itemsToShow.map((item) => (
            <Menu.Item key={item.href} as={Fragment}>
              {({ active }) => (
                <Link
                  href={item.href}
                  passHref
                  className={clsx(
                    active && "bg-grey-3",
                    "p-2 my-2 flex items-center justify-between",
                  )}
                >
                  <strong>{item.label}</strong>
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
