/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

type Primitive = string | number | boolean;

export const useEffectOnce = (
  callback: () => (() => void) | void,
  when: boolean,
  runAgainOn?: Primitive,
) => {
  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (when && !hasRunOnce.current) {
      hasRunOnce.current = true;

      return callback();
    }
  }, [when]);

  useEffect(() => {
    if (runAgainOn && hasRunOnce.current) {
      return callback();
    }
  }, [runAgainOn]);
};
