import { useFavorites } from "@chef/state-management/hooks";
import { DislikeButton } from "./DislikeButton";
import { FavoriteButton } from "./FavoriteButton";

interface RecipeReactionButtonProps {
  recipe: {
    recipeId: number;
    mainRecipeId: number | null;
    recipeName: string;
  };
  className?: string;
}

export const RecipeReactionButton = ({
  recipe,
  className,
}: RecipeReactionButtonProps) => {
  const { isDisliked } = useFavorites();

  if (isDisliked(recipe)) {
    return <DislikeButton recipe={recipe} className={className} />;
  }

  return <FavoriteButton recipe={recipe} className={className} />;
};
