import clsx from "clsx";
import { IconThumbDown, IconThumbDownFilled } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { useFavorites } from "@chef/state-management/hooks";
import { setRecipeModal, showRecipeActionModal } from "@chef/state-management";
import { useTrack } from "@chef/feature-tracking";
import { useFlag } from "@chef/feature-flags";

interface DislikeButtonProps {
  recipe: {
    recipeId: number;
    mainRecipeId: number | null;
    recipeName: string;
  };
  className?: string;
}

export const DislikeButton = ({ recipe, className }: DislikeButtonProps) => {
  const isEnabled = useFlag("global_recipe_reject");

  const {
    isDisliked: isDislikedFn,
    isFavorited,
    toggleDislike,
    toggleFavorite,
    enableFavorites,
  } = useFavorites();

  const dispatch = useDispatch();
  const track = useTrack();

  const isDisliked = isDislikedFn(recipe);

  const handleDislike = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!enableFavorites) {
      dispatch(
        setRecipeModal({
          open: false,
          recipeId: recipe.recipeId,
        }),
      );

      track("buttonClicked", {
        affiliation: "Frontend process",
        button_colour: "outlined",
        button_placement: "dislike-recipe-button",
        button_source: "code",
        button_text: `Dislike button clicked for recipe ${
          recipe.mainRecipeId || recipe.recipeId
        }`,
      });

      dispatch(showRecipeActionModal());

      return;
    }

    if (isFavorited(recipe)) {
      toggleFavorite(recipe);
    }

    toggleDislike(recipe);
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <button
      onClick={handleDislike}
      className={clsx(
        "flex items-center justify-center w-10 h-10 bg-white rounded-full hover:bg-white",
        className,
      )}
    >
      <motion.div
        animate={isDisliked ? { y: [0, 5, 0], rotate: [0, 25, 0] } : {}}
        transition={{ duration: 0.25 }}
      >
        {isDisliked ? (
          <IconThumbDownFilled width={24} height={24} className="text-error" />
        ) : (
          <IconThumbDown width={24} height={24} strokeWidth={1.5} />
        )}
      </motion.div>
    </button>
  );
};
