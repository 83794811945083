import clsx from "clsx";
import { IconShare2 } from "@tabler/icons-react";

import { Button, ButtonCircle } from "@chef/components";
import { useTrack } from "@chef/feature-tracking";
import { showNotification } from "@chef/state-management";

import { intl } from "./ShareButton.Intl";

type ShareButtonProps = (
  | {
      href?: never;
      text?: never;
      onClick: () => Promise<{ href: string; text?: string }>;
    }
  | {
      onClick?: never;
      href: string;
      text?: string;
    }
) & {
  isLoading?: boolean;
  variant?: "descriptive" | "default";
  className?: string;
};

export const ShareButton = ({
  variant = "default",
  isLoading,
  href,
  text,
  onClick,
  className,
}: ShareButtonProps) => {
  const track = useTrack();

  const handleShare = async (href: string, text?: string) => {
    {
      try {
        if (navigator.canShare?.({ title: text, text, url: href })) {
          await navigator.share({ title: text, text, url: href });

          track("linkShared", {
            href: href,
            affiliation: "Frontend process",
            method: "context-share",
            title: text ?? href,
          });
        } else {
          await navigator.clipboard.writeText(href);

          track("linkShared", {
            href: href,
            affiliation: "Frontend process",
            method: "copy-to-clipboard",
            title: text ?? href,
          });

          showNotification({
            type: "success",
            message: intl.LINK_COPIED,
          });
        }
      } catch (error) {
        console.error("Error copying link", error);
      }
    }
  };

  if (variant === "default") {
    return (
      <ButtonCircle
        Icon={() => (
          <IconShare2
            width={22}
            height={22}
            strokeWidth={1.5}
            className="mb-1"
          />
        )}
        onClick={async () => {
          if (href) {
            handleShare(href, text);
          } else if (onClick) {
            const { href, text } = await onClick();
            handleShare(href, text);
          }
        }}
        loading={isLoading}
        className={clsx("bg-white hover:bg-white", className)}
      />
    );
  }

  return (
    <Button
      onClick={async () => {
        if (href) {
          handleShare(href, text);
        } else if (onClick) {
          const { href, text } = await onClick();
          handleShare(href, text);
        }
      }}
      loading={isLoading}
      className={clsx("bg-white hover:bg-white whitespace-nowrap", className)}
      small
    >
      <span className="self-auto mr-2 ">{intl.SHARE}</span>
      <IconShare2 width={22} height={22} strokeWidth={1.5} className="mb-1" />
    </Button>
  );
};
