import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      INVALID_PAYMENT_METHOD:
        "Betalingsmetoden du har valgt er dessverre ikke tilgjengelig. Vennligst velg en annen metode.",
      CONTACT_CS_FOR_AN_OVERVIEW_UNPAID_ORDERS:
        "Ta kontakt med kundeservice for oversikt over dine ubetalte fakturaer.",
    },
    se: {
      INVALID_PAYMENT_METHOD:
        "Den betalningsmetod du valt är tyvärr inte tillgänglig just nu. Välj gärna en annan metod för att slutföra ditt köp.",
      CONTACT_CS_FOR_AN_OVERVIEW_UNPAID_ORDERS:
        "Kontakta kundservice för att få en översikt över dina obetalda fakturor.",
    },
    dk: {
      INVALID_PAYMENT_METHOD:
        "Den valgte betalingsmetode er desværre ikke tilgængelig. Vælg venligst en anden metode.",
      CONTACT_CS_FOR_AN_OVERVIEW_UNPAID_ORDERS:
        "Kontakt kundeservice for at få en oversigt over dine ubetalte fakturaer.",
    },
  } as const
)[language];
