import clsx from "clsx";
import type { PortableTextBlock } from "@portabletext/types";
import { useState } from "react";

import { CONTACT, language } from "@chef/constants";
import { isEmptyArray } from "@chef/utils/array";
import { Button, Hr, Tooltip } from "@chef/components";
import { nonNullable } from "@chef/utils/nonNullable";
import { formatDate } from "@chef/helpers";
import { useIntercom } from "@chef/hooks";
import { removeCountryCode } from "@chef/utils/phone";

import { BlockContent } from "./BlockContent";

type Timespan = {
  _type: "timespan";
  from: string;
  to: string;
  _key: string;
};

type Opening = {
  _type: "opening";
  hours: Timespan[];
  _key: string;
} & (
  | {
      isDefault: true;
      date?: never;
      dayComment?: never;
    }
  | {
      isDefault: false;
      date: string;
      dayComment?: string;
    }
);

type Day = {
  _type: "day";
  _key: string;
  openings: Opening[];
};

type Week = {
  mon: Day;
  tue: Day;
  wed: Day;
  thu: Day;
  fri: Day;
  sat: Day;
  sun: Day;
};

type Timeline = {
  at: Date;
  event: "opens" | "closes" | "now";
}[];

interface TimetableComponentProps {
  value?: {
    _type: "timetable";
    cta: "none" | "chat" | "phone";
    comment: PortableTextBlock[];
  } & Partial<Week>;
}

interface CSCTAProps {
  value: {
    cta: "none" | "chat" | "phone";
  } & Partial<Week>;
}

interface IntercomButtonProps {
  isOpen: boolean;
}

interface OpeningProps {
  opening?: Opening;
}

interface DayProps {
  name: string;
  day?: Day;
  isToday: boolean;
  date?: string;
}

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * ONE_HOUR;

const mod = (n: number, m: number) => ((n % m) + m) % m;

const findDeviatingOpening = (day?: Day) => {
  return day?.openings.find((opening) => {
    if (!opening.date) {
      return false;
    }

    const date = new Date(opening.date);

    // "Now" must be between 144 hours before and 24 hours after the date
    // We would like to show the opening hours for the day on midnight
    // the day after the corresponding day in the week before, which is 6 days (144 hours)
    // We would also like to hide the opening hours for the day on midnight
    // the day after, which is after 24 hours
    return (
      +new Date() - +date > -144 * ONE_HOUR && +new Date() - +date < ONE_DAY
    );
  });
};

const createTimeline = (week: Partial<Week>): Timeline => {
  const now = new Date();

  const today = now.getDay();

  const timeline = [...Array(7)]
    .flatMap((_, i) => {
      const [key] = days[i];

      const day = week[key];

      if (!day) {
        return null;
      }

      const defaultOpening = day.openings.find((opening) => opening.isDefault);

      const deviatedOpening = findDeviatingOpening(day);

      const opening = deviatedOpening || defaultOpening;

      if (!opening || !opening.hours) {
        return null;
      }

      return opening.hours.flatMap((timespan) => {
        if (!timespan.from || !timespan.to) {
          return null;
        }

        const [from_hour, from_minutes] = timespan.from.split(":");
        const [to_hour, to_minutes] = timespan.to.split(":");

        const from = new Date();
        const to = new Date();

        from.setDate(now.getDate() + mod(i - today, 7));
        from.setHours(+from_hour);
        from.setMinutes(+from_minutes);

        to.setDate(now.getDate() + mod(i - today, 7));
        to.setHours(+to_hour);
        to.setMinutes(+to_minutes);

        return [
          { at: from, event: "opens" as const },
          { at: to, event: "closes" as const },
        ];
      });
    })
    .filter(nonNullable)
    .filter((event) => event.at.getTime() > now.getTime()) as Timeline;

  timeline.push({ at: now, event: "now" as const });
  timeline.sort((a, b) => a.at.getTime() - b.at.getTime());

  return timeline;
};

const IntercomButton = ({ isOpen }: IntercomButtonProps) => {
  const { isReady, isUnavailable } = useIntercom();

  const handleOpenChat = () => {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom("showNewMessage");
    }
  };

  if (isUnavailable) {
    return (
      <Tooltip
        element={<p className="w-80">{intl.INTERCOM_UNAVAILABLE_FULL}</p>}
      >
        <span className="underline decoration-dotted">
          {intl.INTERCOM_UNAVAILABLE} <strong>(?)</strong>
        </span>
      </Tooltip>
    );
  }

  if (!isReady) {
    return (
      <Button disabled primary tiny>
        {intl.START_CHATTING}
      </Button>
    );
  }

  return (
    <Button
      disabled={!isOpen}
      onClick={handleOpenChat}
      primary
      tiny
      id="contact-us-btn"
    >
      {intl.START_CHATTING}
    </Button>
  );
};

const CSCTA = ({ value }: CSCTAProps) => {
  if (!value || value.cta === "none") {
    return null;
  }

  const timeline = createTimeline({
    mon: value.mon,
    tue: value.tue,
    wed: value.wed,
    thu: value.thu,
    fri: value.fri,
    sat: value.sat,
    sun: value.sun,
  });

  const next = timeline[1];

  if (!next) {
    return null;
  }

  const nextIsMoreThan24HAway = +next.at > +new Date() + ONE_DAY;

  let formatted = "";

  if (nextIsMoreThan24HAway) {
    formatted = formatDate(next.at, "EEEE HH:mm", { lowerCase: true });
  } else {
    formatted = formatDate(next.at, "HH:mm");
  }

  const isOpen = next.event === "closes";

  const ContactCTA = () => {
    if (value.cta === "chat") {
      return <IntercomButton isOpen={isOpen} />;
    } else if (value.cta === "phone") {
      return (
        <p className="text-sm">
          {intl.YOU_CAN_REACH_US_AT}{" "}
          <a href={`tel:${CONTACT.PHONE}`} className="underline">
            <strong className="ml-1">{intl.PHONE_NUMBER}</strong>
          </a>
        </p>
      );
    } else {
      return <div aria-hidden="true" />;
    }
  };

  return (
    <div className="my-2 not-prose">
      <div className="flex items-center gap-4">
        <ContactCTA />
        <div className="text-right">
          <span
            className={clsx(
              "w-3 h-3 circle",
              isOpen ? "text-information" : "text-error",
            )}
          />
          <strong className="ml-2 text-sm">
            {isOpen ? intl.OPEN_UNTIL(formatted) : intl.OPENS_AT(formatted)}
          </strong>
        </div>
      </div>
    </div>
  );
};

const Opening = ({ opening }: OpeningProps) => {
  if (!opening || !opening.hours || isEmptyArray(opening.hours)) {
    return <div>{intl.CLOSED}</div>;
  }

  return (
    <div>
      {opening.hours
        .map((timespan) => `${timespan.from} - ${timespan.to}`)
        .join(", ")}
    </div>
  );
};

const Day = ({ name, day, isToday, date }: DayProps) => {
  const defaultOpening = day?.openings.find((opening) => opening.isDefault);

  const deviatedOpening = findDeviatingOpening(day);

  const dateString = date ? `, ${date}` : "";

  const El = isToday ? "strong" : "span";

  return (
    <El className="flex justify-between text-sm md:text-base col-span-full">
      <div>
        {name}
        {dateString}
        <div className="text-error">{deviatedOpening?.dayComment}</div>
      </div>
      <div className="text-right">
        <Opening opening={deviatedOpening || defaultOpening} />
        <div className="text-error">
          {deviatedOpening?.dayComment ? intl.SPECIAL_OPENING : ""}
        </div>
      </div>
    </El>
  );
};

export const TimetableComponent = ({ value }: TimetableComponentProps) => {
  const [show, setShow] = useState(false);

  if (!value) {
    return null;
  }

  const todaysWeekday = new Date().getDay();
  const dn = [...Array(7)].map((_, i) => mod(i + todaysWeekday, 7));

  const getLocaleDayAndMonth = (daysFromToday: number) => {
    const date = new Date();
    date.setDate(date.getDate() + daysFromToday);

    return date.toLocaleDateString(intl.LOCALE, {
      day: "numeric",
      month: "short",
    });
  };

  return (
    <div className="flex flex-col gap-2 not-prose">
      <p className="text-base">
        <strong>{intl.OPENING_HOURS}</strong>
      </p>
      <Hr />
      {dn.map((d, i) => {
        const [key, label] = days[d];

        const hideOpening = !show && i !== 0;

        const date = getLocaleDayAndMonth(i);

        return hideOpening ? null : (
          <Day
            key={key}
            name={label}
            day={value[key]}
            date={date}
            isToday={i === 0}
          />
        );
      })}
      <button
        onClick={() => setShow(!show)}
        className="flex justify-start text-base underline text-primary"
      >
        {show ? intl.MINIMIZE_OPENINGS : intl.EXPAND_OPENINGS}
      </button>
      <Hr />

      {value.comment && (
        <BlockContent body={value.comment} prose={{ padding: "none" }} />
      )}
      <CSCTA value={value} />
    </div>
  );
};

const intl = (
  {
    no: {
      MONDAY: "Mandag",
      TUESDAY: "Tirsdag",
      WEDNESDAY: "Onsdag",
      THURSDAY: "Torsdag",
      FRIDAY: "Fredag",
      SATURDAY: "Lørdag",
      SUNDAY: "Søndag",
      CLOSED: "Stengt",
      START_CHATTING: "Start chatten",
      CALL_US: "Ring oss",
      YOU_CAN_REACH_US_AT: "Du kan nå oss på",
      PHONE_NUMBER: removeCountryCode(CONTACT.PHONE, language),
      OPENING_HOURS: "Åpningstider",
      EXPAND_OPENINGS: "Vis alle åpningstider",
      MINIMIZE_OPENINGS: "Skjul åpningstider",
      SPECIAL_OPENING: "Åpningstidene kan variere",
      OPEN_UNTIL: (time: string) => `Åpent til ${time}`,
      OPENS_AT: (time: string) => `Åpner ${time}`,
      LOCALE: "nb-NO",
      INTERCOM_UNAVAILABLE: "Chat utilgjengelig",
      INTERCOM_UNAVAILABLE_FULL:
        "Chat er dessverre ikke tilgjengelig. Dette kan være fordi du bruker ad blocker eller har aktivert Apples tracking transparency. Dersom du ønsker å bruke chat kan du prøve å skru av disse før du prøver igjen. Du kan fortsatt kontakte oss på e-post eller telefon.",
    },
    se: {
      MONDAY: "Måndag",
      TUESDAY: "Tisdag",
      WEDNESDAY: "Onsdag",
      THURSDAY: "Torsdag",
      FRIDAY: "Fredag",
      SATURDAY: "Lördag",
      SUNDAY: "Söndag",
      CLOSED: "Stängt",
      START_CHATTING: "Starta chatten",
      CALL_US: "Ring oss",
      YOU_CAN_REACH_US_AT: "Du når oss på",
      PHONE_NUMBER: removeCountryCode(CONTACT.PHONE, language),
      OPENING_HOURS: "Öppetider",
      EXPAND_OPENINGS: "Visa alla öppetider",
      MINIMIZE_OPENINGS: "Göm öppetider",
      SPECIAL_OPENING: "Tiderna kan variera",
      OPEN_UNTIL: (time: string) => `Öppet till ${time}`,
      OPENS_AT: (time: string) => `Öppnar ${time}`,
      LOCALE: "sv-SE",
      INTERCOM_UNAVAILABLE: "Chatten är otillgänglig",
      INTERCOM_UNAVAILABLE_FULL:
        "Chatten är tyvärr inte tillgänglig. Detta kan bero på att du använder ad blocker eller har aktiverat Apples tracking transparency. Om du vill använda chatten kan du försöka stänga av dessa innan du försöker igen. Du kan fortfarande kontakta oss via e-post eller telefon.",
    },
    dk: {
      MONDAY: "Mandag",
      TUESDAY: "Tirsdag",
      WEDNESDAY: "Onsdag",
      THURSDAY: "Torsdag",
      FRIDAY: "Fredag",
      SATURDAY: "Lørdag",
      SUNDAY: "Søndag",
      CLOSED: "Lukket",
      START_CHATTING: "Start chatten",
      CALL_US: "Ring os",
      YOU_CAN_REACH_US_AT: "Kontakt os på",
      PHONE_NUMBER: removeCountryCode(CONTACT.PHONE, language),
      OPENING_HOURS: "Åbningstider",
      EXPAND_OPENINGS: "Vis alle åbningstider",
      MINIMIZE_OPENINGS: "Vis mindre",
      SPECIAL_OPENING: "Åbningstiderne kan variere",
      OPEN_UNTIL: (time: string) => `Åbent til ${time}`,
      OPENS_AT: (time: string) => `Åbner ${time}`,
      LOCALE: "da-DK",
      INTERCOM_UNAVAILABLE: "Chatten er utilgængelig",
      INTERCOM_UNAVAILABLE_FULL:
        "Chatten er desværre ikke tilgængelig. Dette kan skyldes, at du bruger ad blocker eller har aktiveret Apples tracking transparency. Hvis du vil bruge chatten, kan du prøve at slå disse fra, inden du prøver igen. Du kan stadig kontakte os via e-mail eller telefon.",
    },
  } as const
)[language];

const days = [
  ["sun", intl.SUNDAY],
  ["mon", intl.MONDAY],
  ["tue", intl.TUESDAY],
  ["wed", intl.WEDNESDAY],
  ["thu", intl.THURSDAY],
  ["fri", intl.FRIDAY],
  ["sat", intl.SATURDAY],
] as const;
