import { AnimatedHeart, Button } from "@chef/components";

import { intl } from "./NoFavorites.Intl";

interface NoFavoritesProps {
  onReset: () => void;
}

export const NoFavoritesThisWeek = ({ onReset }: NoFavoritesProps) => {
  return (
    <div className="flex flex-col items-center gap-6 col-span-full">
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-grey-3">
        <AnimatedHeart isFilled={false} className="w-12 h-12 mt-1" />
      </div>
      <h2>
        <strong>{intl.YOU_HAVE_NO_FAVORITES_THIS_WEEK}</strong>
      </h2>
      <p>{intl.WHEN_SOME_OF_YOUR_FAVORITES_ARE_AVAILABLE}</p>
      <Button onClick={onReset} primary>
        {intl.SHOW_WHOLE_MENU}
      </Button>
    </div>
  );
};

export const NoFavorites = ({ onReset }: NoFavoritesProps) => {
  return (
    <div className="flex flex-col items-center gap-6 text-center col-span-full">
      <div className="flex items-center justify-center w-20 h-20 rounded-full bg-grey-3">
        <AnimatedHeart isFilled={false} className="w-12 h-12 mt-1" />
      </div>
      <h2>
        <strong>{intl.YOU_HAVE_NO_FAVORITES}</strong>
      </h2>
      <p>{intl.CLICK_ON_THE_HEART_ON_THE_RECIPE_TO_ADD}</p>
      <Button onClick={onReset} primary>
        {intl.SHOW_ALL_MY_RECIPES}
      </Button>
    </div>
  );
};
