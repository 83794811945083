import { useEffect } from "react";
import { useRouter } from "next/router";
import { GetStaticPropsContext, InferGetStaticPropsType } from "next";

import {
  api,
  RecipeAndStepsQuery,
  storeWrapper,
  useMeQuery,
} from "@chef/state-management";
import { findFeaturedPortraitImage } from "@chef/state-management/helpers";
import { AppShell } from "@chef/feature-app-shell";
import { BRAND_PATHS } from "@chef/constants";
import { RecipeActionModal } from "@chef/smart-components";
import { slugify } from "@chef/helpers";

import { Recipe } from "../libs/recipe/Recipe";

import { intl } from "./RecipePage.Intl";

export const RecipePage = ({
  recipe,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { data: user } = useMeQuery();

  const isLoggedIn = Boolean(user);

  const router = useRouter();
  const { print } = router.query;

  const { recipeName, recipeDescription, recipeNameHeadline } = recipe;
  const metaImage = findFeaturedPortraitImage(recipe)?.url;

  useEffect(() => {
    if (!print) {
      return;
    }

    window.print();
  }, [print]);

  const canonical = BRAND_PATHS.RECIPE_WITH_NAME_HREF({
    recipeId: recipe.mainRecipeId || recipe.recipeId,
    recipeName: slugify(recipeName),
  });

  const name = recipeNameHeadline || recipeName;

  return (
    <AppShell
      title={`${name} - ${intl.RECIPE}`}
      description={recipeDescription}
      image={metaImage}
      canonical={canonical}
    >
      <AppShell.Meta />
      <AppShell.Header printHidden />

      <AppShell.Main transparent>
        <Recipe recipe={recipe} showCTA={!isLoggedIn} />
        <RecipeActionModal />
      </AppShell.Main>

      <AppShell.Footer />
    </AppShell>
  );
};

export const getStaticProps = storeWrapper.getStaticProps(
  (store) =>
    async ({
      params,
    }: GetStaticPropsContext): Promise<
      | {
          props: { recipe: RecipeAndStepsQuery["recipeAndSteps"] };
          revalidate: number;
        }
      | {
          redirect: { destination: string; permanent: boolean };
          revalidate: number;
        }
    > => {
      const id = params?.["id"];

      // Check that the id is a valid number
      if (!id || isNaN(+id)) {
        return {
          redirect: {
            destination: "/",
            permanent: false,
          },
          revalidate: 600,
        };
      }

      try {
        const { recipeAndSteps } = await store
          .dispatch(
            api.endpoints.recipeAndSteps.initiate({
              recipeId: +id,
            }),
          )
          .unwrap();

        return {
          props: {
            recipe: recipeAndSteps,
          },
          revalidate: 600,
        };
      } catch (e) {
        return {
          redirect: {
            destination: BRAND_PATHS.RECIPE_HUB_PATH,
            permanent: false,
          },
          revalidate: 600,
        };
      }
    },
);

export const getStaticPaths = () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};
