import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      CLOSE: "Lukk",
      CONTENTS: "Innhold",
      NETTOWEIGHT: "Nettovekt",
      INGREDIENTS: "Ingredienser",
      ALLERGIES: "Allergener",
      SUPPLIER: "Leverandør",
      NUTRITION_FACTS: "Næringsinnhold per 100 g",

      OFFERS: "Tilbud",
      ADD: "Legg til",
      stockState: {
        "limited-stock": "Begrenset antall",
        "maximum-amount-added": "Maks antall valgt",
        "sold-out": "Utsolgt",
      },
    },

    dk: {
      CLOSE: "Luk",
      CONTENTS: "Indhold",
      NETTOWEIGHT: "Størrelse",
      INGREDIENTS: "Ingredienser",
      ALLERGIES: "Allergener",
      SUPPLIER: "Leverandør",
      NUTRITION_FACTS: "Næringsindhold per 100 g",

      OFFERS: "Tilbud",
      ADD: "Tilføj",
      stockState: {
        "limited-stock": "Begrænset antal",
        "maximum-amount-added": "Max antal valgt",
        "sold-out": "Udsolgt",
      },
    },

    se: {
      CLOSE: "Stäng",
      CONTENTS: "Innehållsförteckning",
      NETTOWEIGHT: "Mängd/vikt",
      INGREDIENTS: "Ingredienser",
      ALLERGIES: "Allergener",
      SUPPLIER: "Leverantör",
      NUTRITION_FACTS: "Näringsvärde, per 100 gram",

      OFFERS: "Kampanj",
      ADD: "Lägg til",
      stockState: {
        "limited-stock": "Begränsat antal kvar",
        "maximum-amount-added": "Max antal valda",
        "sold-out": "Slutsåld",
      },
    },
  } as const
)[language];
