import { ACCOUNT_STATUSES } from "@chef/constants";

const isAccountActive = (status: number) => status === ACCOUNT_STATUSES.ACTIVE;

const isAccountPaused = (status: number) => status === ACCOUNT_STATUSES.PAUSED;

const isAccountPending = (status: number) =>
  status === ACCOUNT_STATUSES.PENDING;

const isAccountBadPayer = (status: number) =>
  status === ACCOUNT_STATUSES.BAD_PAYER;

const isAccountPostalAreaClosed = (status: number) =>
  status === ACCOUNT_STATUSES.POSTAL_AREA_CLOSED;

const isAccountLocked = (status: number) => status === ACCOUNT_STATUSES.LOCKED;

const isAccountMissingKey = (status: number) =>
  status === ACCOUNT_STATUSES.MISSING_KEY;

const isAccountDeleted = (status: number) =>
  status === ACCOUNT_STATUSES.DELETED;

export {
  isAccountActive,
  isAccountPaused,
  isAccountPending,
  isAccountBadPayer,
  isAccountPostalAreaClosed,
  isAccountLocked,
  isAccountMissingKey,
  isAccountDeleted,
};
