import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import { IconArrowNarrowRight } from "@tabler/icons-react";

import { BRAND_PATHS } from "@chef/constants";
import { useBillingQuery } from "@chef/state-management";
import { usePersistentState } from "@chef/hooks";
import { STAGE, usePreselector } from "@chef/state-management/hooks";

import { brand, intl } from "./PreferenceQuizNudge.Intl";

export const PreferenceQuizNudge = () => {
  const { data: billingQuery } = useBillingQuery();

  const { stage, expire } = usePreselector({
    onStageChange: {
      [STAGE.COMPLETED]: () => {
        expire();
      },
      [STAGE.COMPLETED_WITH_ALLERGEN]: () => {
        expire();
      },
    },
  });

  const isPreselectorRunning = stage > STAGE.IDLE && stage < STAGE.COMPLETED;

  const [hasClickedNudge, setHasClickedNudge] = usePersistentState(
    "has_clicked_preference_quiz_nudge",
    false,
  );

  if (!billingQuery || isPreselectorRunning) {
    return null;
  }

  const isSignedUpWithOneSub = billingQuery.billing.agreementId > 13860110;

  if (hasClickedNudge || isSignedUpWithOneSub) {
    return null;
  }

  return (
    <motion.div
      className="relative rounded-lg lg:col-start-2 xl:col-start-3 xl:col-span-8 lg:col-span-6 col-span-full group"
      style={{ backgroundColor: brand.BACKGROUND_COLOR }}
      whileHover="hover"
      whileTap={{ scale: 0.95 }}
    >
      <Link
        href={BRAND_PATHS.MEALBOX_PREFERENCE}
        className="flex p-4"
        onClick={() => setHasClickedNudge(true)}
      >
        <Image
          src={brand.DECORATIVE_IMG}
          alt={intl.DECORATIVE_VEGGIES}
          width={126}
          height={128}
          className="absolute right-0 w-32 -bottom-1 lg:w-24"
        />
        <div className="flex flex-col text-sm lg:flex-row group lg:gap-1 lg:items-center">
          <strong>{intl.TIME_TO_REVIEW_YOUR_CHOICES}</strong>
          <span>{intl.MAKE_YOUR_EXPERIENCE_MORE_PERSONAL}</span>
          <div className="flex underline text-primary lg:items-center">
            <strong>{intl.SHOW_THE_NEW_PREFERENCES}</strong>
            <motion.div
              variants={{ default: { x: 0 }, hover: { x: 5 } }}
              className="lg:mt-px"
            >
              <IconArrowNarrowRight />
            </motion.div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};
