import React, { ButtonHTMLAttributes, SVGProps } from "react";
import clsx from "clsx";

import { Loading } from "@chef/icons/small";
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  error?: boolean;
  tiny?: boolean;
  small?: boolean;
  disabled?: boolean;
  loading?: boolean;
  full?: boolean;
  label?: string;
  id?: string;
  IconLeft?: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  IconRight?: (props: SVGProps<SVGSVGElement>) => React.ReactElement | null;
  href?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      primary = false,
      secondary = false,
      outlined = false,
      error = false,
      tiny = false,
      small = false,
      disabled = false,
      loading = false,
      full = false,
      label,
      IconLeft,
      IconRight,
      href,
      className,
      children,
      id,
      ...props
    },
    ref,
  ) => {
    const hasIcon = !!(IconLeft || IconRight);

    const baseStyle = clsx(
      "relative inline-flex h-fit items-center th-gl:rounded-full th-amk:rounded th-lmk:rounded-full th-rn:rounded a11y-focus:focus-ring",
      hasIcon && "justify-between",
      !hasIcon && "justify-center",
      disabled && "cursor-not-allowed",
    );

    const sizeStyles = {
      default: "py-3 px-5 text-base",
      small: "py-2 px-4",
      tiny: "py-1.5 px-3 text-sm",
    };

    const typeStyles = {
      primary: clsx("text-white th-lmk:text-grey-3", {
        "bg-error": error,
        "bg-disabled": disabled,
        "bg-primary hover:bg-hover": !disabled && !error,
      }),
      secondary: clsx("text-white", {
        "bg-error": error,
        "bg-secondary/40": disabled,
        "bg-secondary hover:bg-secondary/90": !disabled && !error,
      }),
      default: clsx("text-black", disabled ? "text-grey-1" : "bg-grey-2"),
      outlined: clsx("outline outline-2 focus:outline-2", {
        "focus:outline-error outline-error hover:bg-error hover:text-white":
          error && !disabled,
        "focus:outline-disabled outline-disabled text-grey-1": disabled,
        "focus:outline-primary outline-primary text-black hover:bg-primary/10":
          !disabled && !error,
      }),
    };

    let size: "default" | "small" | "tiny" = "default";
    if (small) {
      size = "small";
    }
    if (tiny) {
      size = "tiny";
    }

    let type: "default" | "primary" | "secondary" | "outlined" = "default";
    if (primary) {
      type = "primary";
    }
    if (secondary) {
      type = "secondary";
    }
    if (outlined) {
      type = "outlined";
    }

    let element = "button";
    if (href) {
      element = "a";
    }

    return React.createElement(
      element,
      {
        className: clsx(
          baseStyle,
          sizeStyles[size],
          typeStyles[type],
          full && "w-full",
          className,
        ),
        "aria-label": label,
        "aria-busy": loading ? "true" : "false",
        disabled: disabled || loading,
        href,
        id,
        ref,
        ...props,
      },
      <>
        {loading && (
          <Loading className="absolute inset-0 m-auto animate-spin" />
        )}

        {IconLeft && (
          <div className="w-6">
            <IconLeft
              className={clsx("inline text-sm mr-1.5", loading && "invisible")}
              aria-hidden="true"
            />
          </div>
        )}

        {children && (
          <span
            className={clsx(
              "grow justify-center text-center",
              loading && "invisible",
            )}
          >
            {children}
          </span>
        )}

        {IconRight && (
          <div className="w-6">
            <IconRight
              className={clsx("inline text-sm ml-1.5", loading && "invisible")}
              aria-hidden="true"
            />
          </div>
        )}
      </>,
    );
  },
);
