import { useMemo } from "react";
import { useSelector } from "react-redux";

import { config, PRODUCT_CATEGORY_IDS } from "@chef/constants";
import { isOneSubProduct } from "@chef/helpers";
import { isNotEmptyArray } from "@chef/utils/array";

import { selectDeviationProducts } from "../features";
import { useProductsByCategoriesQuery } from "../graphql/generated";
import { getMealboxVariationFromBasket } from "../helpers/basket";
import {
  getAttribute,
  getMealsFromVariations,
  isPickAndMixProduct,
} from "../helpers/product";

// The purpose of this hook is to give access to commonly used data that is often needed
// when dealing with the basket. Do not include a bunch of niche stuff in this hook.
export const useExtendedBasketData = (args: {
  week: number;
  year: number;
  signup?: boolean;
}) => {
  const { week, year, signup = false } = args;
  const skip = !week || !year;

  const selectedProducts = useSelector(selectDeviationProducts({ week, year }));

  const {
    data: productsByCategoriesQuery,
    isLoading: isLoadingProductsByCategories,
    isFetching: isFetchingProductsByCategories,
    isError: isErrorProductsByCategories,
  } = useProductsByCategoriesQuery(
    {
      categoryIds: [
        signup
          ? PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_OUT
          : PRODUCT_CATEGORY_IDS.MEALBOX_LOGGED_IN,
      ],
      week,
      year,
    },
    { skip },
  );

  const productsByCategories = productsByCategoriesQuery?.productsByCategories;

  const mealboxProduct = productsByCategories
    ?.flatMap((category) => category?.products)
    .find(isOneSubProduct);

  const mealboxVariation = useMemo(() => {
    if (!productsByCategories) {
      return null;
    }

    return getMealboxVariationFromBasket({
      productsByCategories,
      basketProducts: selectedProducts || [],
    });
  }, [selectedProducts, productsByCategories]);

  const selectedPortions = useMemo(() => {
    if (!mealboxVariation) {
      return config.order.defaultPortions;
    }
    return +(
      getAttribute("Portions", mealboxVariation) || config.order.defaultPortions
    );
  }, [mealboxVariation]);

  const selectedMeals = useMemo(() => {
    if (!mealboxVariation) {
      if (!selectedProducts) {
        return config.order.defaultMeals;
      }
      return selectedProducts.filter(isPickAndMixProduct).length;
    }

    return +(
      getAttribute("Meals", mealboxVariation) || config.order.defaultMeals
    );
  }, [mealboxVariation]);

  const mealsOptionsForSelectedPortions = getMealsFromVariations({
    variations: mealboxProduct?.variations,
    portions: selectedPortions,
  });

  const minMealsForSelectedPortions = isNotEmptyArray(
    mealsOptionsForSelectedPortions,
  )
    ? Math.min(...mealsOptionsForSelectedPortions)
    : config.order.minMeals;

  return {
    data: {
      selectedProducts,
      mealboxVariation,
      mealboxProduct,
      mealsOptions: mealsOptionsForSelectedPortions,
      minMeals: minMealsForSelectedPortions,
      portions: selectedPortions,
      meals: selectedMeals,
    },
    isLoading: isLoadingProductsByCategories,
    isFetching: isFetchingProductsByCategories,
    isError: isErrorProductsByCategories,
  };
};
